






import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
    'navLayout': () => import('@/components/business/header/InsNavLayout.vue')
  }
})
export default class InsMenu extends Vue {
  @Prop({ default: 'header' }) private type!: string;
  @Prop({ default: 0 }) private layout!: number;
    CurrentNav: string = 'navLayout';

    get NavData () {
      if (this.type === 'header') {
        let headerMenus = this.$store.state.headerMenus;
        headerMenus.forEach(item => {
          if (item.Childs && item.Childs.length) {
            this.$set(item, 'ShowList', false);
          }
        });
        return this.$store.state.headerMenus;
      } else {
        return this.$store.state.footerMenus;
      }
    }
    mounted () {
      // console.log(this.NavData, 'PC菜单');
    }
    created () {
      if (this.layout) {
        this.CurrentNav = this.CurrentNav + this.layout;
      }
    }
}
